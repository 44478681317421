import { type FC } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";

import { faEye } from "@fortawesome/pro-duotone-svg-icons/faEye";
import { faPencil } from "@fortawesome/pro-duotone-svg-icons/faPencil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  type Action__Branch,
  type Action__Iterator,
  Action__IteratorKind,
  type Action__Notify,
  type Action__Prompt,
  type Action__Prompt__Message,
  type Action__Transcription,
  type StepFragment,
  StepKindEnum,
  type WorkflowFragment,
  useDashboardWorkflowsDetailsQuery,
} from "@app_schema";

import { groupParentID } from "@application/utilities/group_parent_id";

import { Attribute } from "@styled/attribute";
import { Attributes } from "@styled/attributes";
import { Badge } from "@styled/badge";
import { Badges } from "@styled/badges";
import { Headline } from "@styled/headline";
import { NewLink } from "@styled/new_link";
import { Node } from "@styled/node";
import { NodeContent } from "@styled/node_content";
import { NodeGroup } from "@styled/node_group";
import { NodeLink } from "@styled/node_link";
import { NodeList } from "@styled/node_list";
import { NodeMenu } from "@styled/node_menu";
import { NodeName } from "@styled/node_name";
import { NodeSummary } from "@styled/node_summary";
import { Page } from "@styled/page";

import { STEP_KIND_ICON } from "./step_kind_icon";
import { WorkflowCloneButton } from "./workflow_clone_button";
import { WorkflowModifyLink } from "./workflow_modify_link";
import { WorkflowStepDecrementNodeButton } from "./workflow_step_decrement_node_button";
import { WorkflowStepDestroyNodeButton } from "./workflow_step_destroy_node_button";
import { WorkflowStepIncrementNodeButton } from "./workflow_step_increment_node_button";
import { WorkflowsDestroyButton } from "./workflows_destroy_button";
import { WorkflowsRunButton } from "./workflows_run_button";

const ActionIteratorNodeSummary: FC<{
  action: Pick<
    Action__Iterator,
    | "id"
    | "kind"
    | "timesIterations"
    | "overKey"
    | "untilKey"
    | "iterationLimit"
  >;
}> = ({ action }) => (
  <NodeSummary>
    {(() => {
      switch (action.kind) {
        case Action__IteratorKind.Times:
          return <>{action.timesIterations} Times</>;
        case Action__IteratorKind.Over:
          return <>Over "{action.overKey}"</>;
        case Action__IteratorKind.Until:
          return <>Until "{action.untilKey}"</>;
        default:
          return null;
      }
    })()}
    {action.iterationLimit && (
      <>(w/ iteration limit of {action.iterationLimit})</>
    )}
  </NodeSummary>
);

const ActionBranchNodeSummary: FC<{
  action: Pick<Action__Branch, "id" | "params"> & {
    workflow: Pick<WorkflowFragment, "id" | "name">;
  };
}> = ({ action }) => (
  <NodeSummary>
    <p>
      Workflow:{" "}
      <Link to={`/dashboard/workflows/${action.workflow.id}`}>
        "{action.workflow.name}"
      </Link>
    </p>
    {action.params && <p>Params: "{action.params}"</p>}
  </NodeSummary>
);

const ActionNotifyNodeSummary: FC<{
  action: Pick<Action__Notify, "id" | "to" | "subject" | "body" | "language">;
}> = ({ action }) => (
  <Attributes>
    <Attribute name="Language">{action.language}</Attribute>
    <Attribute name="To">{action.to}</Attribute>
    <Attribute name="Subject">{action.subject}</Attribute>
    <Attribute name="Body">{action.body}</Attribute>
  </Attributes>
);

const ActionTranscriptionNodeSummary: FC<{
  action: Pick<Action__Transcription, "id" | "path">;
}> = ({ action }) => (
  <NodeSummary>
    <p>Path: "{action.path}"</p>
  </NodeSummary>
);

const ActionTextNodeSummary: FC<{
  text: string;
}> = ({ text }) => (
  <NodeSummary>
    <pre className="truncate">{text}</pre>
  </NodeSummary>
);

const ActionPromptNodeSummary: FC<{
  action: Pick<Action__Prompt, "id"> & {
    messages: Pick<Action__Prompt__Message, "id" | "role" | "template">[];
  };
}> = ({ action }) => (
  <NodeSummary>
    {action.messages.map((message) => (
      <pre key={message.id} className="truncate">
        {message.template}
      </pre>
    ))}
  </NodeSummary>
);

const ActionNodeSummary: FC<{
  action: StepFragment["action"];
}> = ({ action }) => {
  switch (action.__typename) {
    case "Action__Branch":
      return <ActionBranchNodeSummary action={action} />;
    case "Action__Iterator":
      return <ActionIteratorNodeSummary action={action} />;
    case "Action__Prompt":
      return <ActionPromptNodeSummary action={action} />;
    case "Action__Code":
      return <ActionTextNodeSummary text={action.template} />;
    case "Action__Formatter":
      return <ActionTextNodeSummary text={action.template} />;
    case "Action__Input":
      return null;
    case "Action__Learn":
      return <ActionTextNodeSummary text={action.name} />;
    case "Action__Output":
      return <ActionTextNodeSummary text={action.template} />;
    case "Action__Speech":
      return <ActionTextNodeSummary text={action.template} />;
    case "Action__Transcription":
      return <ActionTranscriptionNodeSummary action={action} />;
    case "Action__Notify":
      return <ActionNotifyNodeSummary action={action} />;
    default:
      throw new Error(`Unknown: ${action}`);
  }
};

const DashboardWorkflowsDetailsStepNode: FC<{
  decrement?: boolean;
  increment?: boolean;
  step: StepFragment;
  workflow: WorkflowFragment;
}> = ({ decrement, increment, step, workflow }) => (
  <Node>
    <NodeContent>
      <NodeName>
        <FontAwesomeIcon icon={STEP_KIND_ICON[step.kind]} />{" "}
        <span>{step.name}</span>
      </NodeName>

      <ActionNodeSummary action={step.action} />
    </NodeContent>
    <NodeMenu>
      <WorkflowStepDecrementNodeButton
        disabled={!decrement}
        workflow={workflow}
        step={step}
      />
      <WorkflowStepIncrementNodeButton
        disabled={!increment}
        workflow={workflow}
        step={step}
      />
      <NodeLink to={`steps/${step.id}`}>
        <FontAwesomeIcon icon={faEye} />
      </NodeLink>
      <NodeLink to={`steps/${step.id}/edit`}>
        <FontAwesomeIcon icon={faPencil} />
      </NodeLink>
      <WorkflowStepDestroyNodeButton workflow={workflow} step={step} />
    </NodeMenu>
  </Node>
);

const DashboardWorkflowsDetailsStepGroup: FC<{
  decrement?: boolean;
  increment?: boolean;
  step: StepFragment;
  workflow: WorkflowFragment;
  groups: Map<string, StepFragment[]>;
}> = ({ decrement, increment, step, workflow, groups }) => {
  const node = (
    <DashboardWorkflowsDetailsStepNode
      increment={increment}
      decrement={decrement}
      step={step}
      workflow={workflow}
    />
  );

  if (step.kind === StepKindEnum.Iterator) {
    return (
      <NodeGroup root={node}>
        <>
          <DashboardWorkflowsDetailsStepList
            workflow={workflow}
            steps={groups.get(step.id) ?? []}
            groups={groups}
          />
          <NewLink
            to={`/dashboard/workflows/${workflow.id}/steps/new?parent_id=${step.id}`}
          >
            Step
          </NewLink>
        </>
      </NodeGroup>
    );
  } else {
    return node;
  }
};

const DashboardWorkflowsDetailsStepList: FC<{
  workflow: WorkflowFragment;
  steps: StepFragment[];
  groups: Map<string, StepFragment[]>;
}> = ({ steps, workflow, groups }) => (
  <NodeList>
    {steps.map((step, index) => (
      <DashboardWorkflowsDetailsStepGroup
        key={step.id}
        groups={groups}
        workflow={workflow}
        step={step}
        increment={index < steps.length - 1}
        decrement={index > 0}
      />
    ))}
  </NodeList>
);

export const DashboardWorkflowsDetails: FC = () => {
  const navigate = useNavigate();
  const { workflowID } = useParams<{ workflowID: string }>();
  const { data, loading, error, refetch } = useDashboardWorkflowsDetailsQuery({
    variables: { workflowID: workflowID! },
  });
  const workflow = data?.workflow;
  if (!workflow) return null;

  const retry = () => refetch({ workflowID: workflowID! });

  const steps = workflow.steps.filter(({ deleted }) => !deleted);
  const groups = groupParentID(steps);

  return (
    <Page loading={loading} error={error} retry={retry}>
      <Headline title={workflow.name}>
        <WorkflowsRunButton workflow={workflow} />
        <WorkflowModifyLink workflow={workflow} />
        <NewLink to={`/dashboard/workflows/${workflowID}/steps/new`}>
          Step
        </NewLink>
        <WorkflowCloneButton workflow={workflow} />
        <WorkflowsDestroyButton
          workflow={workflow}
          onDestroy={() => navigate("/dashboard/workflows")}
        />
      </Headline>

      <Attribute name="Parameters">
        <Badges>
          {workflow.parameters.length === 0 && <>N/A</>}
          {workflow.parameters.map((parameter) => (
            <Badge key={parameter.id}>
              {parameter.name} ({parameter.kind})
            </Badge>
          ))}
        </Badges>
      </Attribute>

      <NodeList>
        <DashboardWorkflowsDetailsStepList
          workflow={workflow}
          steps={steps.filter((step) => !step.parentID)}
          groups={groups}
        />
      </NodeList>
    </Page>
  );
};
