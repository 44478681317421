import { type FC } from "react";
import { Link, useParams } from "react-router-dom";

import { useBlogListQuery } from "@app_schema";

import { useTitle } from "@application/hooks/use_title";
import { useTrackPageView } from "@application/hooks/use_track_page_view";

import { BlogArticleDate } from "./blog_article_date";
import { BlogArticleTags } from "./blog_article_tags";
import { BlogPage } from "./blog_page";
import { BlogTitle } from "./blog_title";

export const BlogList: FC = () => {
  const { tag } = useParams<{ tag: string }>();
  const { data } = useBlogListQuery({ variables: { tag } });
  const blog = data?.blog;

  useTitle("Workflow", "Blog", tag && `#${tag}`);
  useTrackPageView({ page: "blog" });

  return (
    <BlogPage>
      {tag && <BlogTitle>#{tag}</BlogTitle>}
      <div className="flex flex-col gap-4">
        {blog?.articles?.map((article) => (
          <div key={article.id} className="flex flex-col gap-2">
            <BlogArticleDate article={article} />

            <Link
              to={`/blog/articles/${article.slug}`}
              className="text-3xl font-black text-indigo-600 hover:underline"
            >
              {article.title}
            </Link>

            <p className="text-2xl font-semibold text-slate-600">
              {article.summary}
            </p>

            <BlogArticleTags article={article} />
          </div>
        ))}
      </div>
    </BlogPage>
  );
};
