import { forwardRef } from "react";

import {
  type Connection__Provider,
  useDashboardConnectionsListQuery,
} from "@app_schema";

import { SelectField, type SelectFieldProps } from "@styled/select_field";

type ConnectionSelectFieldProps = Omit<SelectFieldProps, "placeholder"> & {
  placeholder?: boolean;
  provider?: Connection__Provider;
};

export const ConnectionSelectField = forwardRef<
  HTMLSelectElement,
  ConnectionSelectFieldProps
>(({ placeholder, disabled, provider, ...props }, ref) => {
  const { data, loading } = useDashboardConnectionsListQuery();

  const connections = data?.connections.filter(
    (connection) =>
      !connection.deleted && (!provider || connection.provider === provider),
  );

  return (
    <SelectField {...props} disabled={disabled ?? loading} ref={ref}>
      <option value="" disabled={!placeholder}>
        - Connection -
      </option>

      {connections?.map((connection) => (
        <option key={connection.id} value={connection.id}>
          {connection.name} (UID: {connection.uid})
        </option>
      ))}
    </SelectField>
  );
});
