import { type FC } from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";

import { faIdCardClip as faName } from "@fortawesome/pro-duotone-svg-icons/faIdCardClip";
import { faPlusSquare } from "@fortawesome/pro-duotone-svg-icons/faPlusSquare";
import { faTrash } from "@fortawesome/pro-duotone-svg-icons/faTrash";
import { faUndo } from "@fortawesome/pro-duotone-svg-icons/faUndo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  ParameterKindEnum,
  type WorkflowErrors,
  type WorkflowFragment,
  type WorkflowInput,
} from "@app_schema";

import { Button } from "@styled/button";
import { Field } from "@styled/field";
import { Fields } from "@styled/fields";
import { Form } from "@styled/form";
import { Group } from "@styled/group";
import { InputField } from "@styled/input_field";
import { Label } from "@styled/label";
import { Notification } from "@styled/notification";
import { Page } from "@styled/page";
import { SelectField } from "@styled/select_field";
import { Sentence } from "@styled/sentence";

const KINDS: ParameterKindEnum[] = [
  ParameterKindEnum.String,
  ParameterKindEnum.Text,
  ParameterKindEnum.Number,
  ParameterKindEnum.Boolean,
  ParameterKindEnum.Vault,
  ParameterKindEnum.Connection,
];

const KIND_TO_NAME: Record<ParameterKindEnum, string> = {
  [ParameterKindEnum.String]: "String",
  [ParameterKindEnum.Text]: "Text",
  [ParameterKindEnum.Number]: "Number",
  [ParameterKindEnum.Boolean]: "Boolean",
  [ParameterKindEnum.Vault]: "Vault",
  [ParameterKindEnum.Connection]: "Connection",
};

export const ParametersFieldset: FC = () => {
  const form = useFormContext<WorkflowInput>();

  const { fields, append, update, remove } = useFieldArray({
    control: form.control,
    name: "parameters",
    keyName: "key",
  });

  const onDelete = (index: number) => {
    const { id, _destroy } = fields[index];
    if (id) update(index, { id, _destroy: !_destroy });
    else remove(index);
  };

  const onRestore = (index: number) => {
    const { id, _destroy } = fields[index];
    update(index, { id, _destroy: !_destroy });
  };

  return (
    <Field>
      <Label>Parameters:</Label>

      <Group>
        {fields.map((field, index) => (
          <Fields key={field.key}>
            <InputField
              disabled={!!field._destroy}
              {...form.register(`parameters.${index}.name`, {
                required: "required",
              })}
              id={`parameters_${index}_name`}
              placeholder="Name"
            />

            <SelectField
              full
              disabled={!!field._destroy}
              {...form.register(`parameters.${index}.kind`, {
                required: "required",
              })}
              id={`parameters_${index}_kind`}
            >
              <option
                disabled={!!form.watch(`parameters.${index}.kind`)}
                value=""
              >
                - Kind -
              </option>
              {KINDS.map((kind) => (
                <option key={kind} value={kind}>
                  {KIND_TO_NAME[kind]}
                </option>
              ))}
            </SelectField>

            {field._destroy ? (
              <Button
                type="button"
                color="rose"
                onClick={() => onRestore(index)}
              >
                <FontAwesomeIcon icon={faUndo} />
              </Button>
            ) : (
              <Button
                type="button"
                color="rose"
                onClick={() => onDelete(index)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            )}
          </Fields>
        ))}

        <div>
          <Button type="button" onClick={() => append({})}>
            <FontAwesomeIcon icon={faPlusSquare} /> Parameter
          </Button>
        </div>
      </Group>
    </Field>
  );
};

export const DashboardWorkflowsForm: FC<{
  workflow?: WorkflowFragment;
  errors?: WorkflowErrors | null;
  loading?: boolean;
  save(_: WorkflowInput): void;
}> = ({ workflow, loading, errors, save }) => {
  const form = useForm<WorkflowInput>({
    defaultValues: workflow
      ? {
          name: workflow.name,
          parameters: workflow.parameters.map(({ id, name, kind }) => ({
            id,
            name,
            kind,
          })),
        }
      : undefined,
  });

  const onSubmit = async (input: WorkflowInput) => {
    if (loading) return;
    save(input);
  };

  return (
    <Page>
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(onSubmit)}>
          {errors?.base && (
            <Notification color="rose">
              <Sentence>{errors.base}</Sentence>
            </Notification>
          )}

          <InputField
            {...form.register("name", { required: "required" })}
            id="name"
            type="text"
            label="Name:"
            placeholder="e.g. Payroll"
            icon={faName}
            errors={errors}
          />

          <ParametersFieldset />

          <Button type="submit" loading={loading}>
            Save
          </Button>
        </Form>
      </FormProvider>
    </Page>
  );
};
