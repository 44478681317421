import { type FC } from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";

import {
  type ParameterFragment,
  ParameterKindEnum,
  RunInput,
  type WorkflowFragment,
} from "@app_schema";

import { Button } from "@styled/button";
import { CheckboxField } from "@styled/checkbox_field";
import { Form } from "@styled/form";
import { InputField } from "@styled/input_field";
import { Page } from "@styled/page";
import { TextAreaField } from "@styled/text_area_field";

import { ConnectionSelectField } from "./connection_select_field";
import { VaultSelectField } from "./vault_select_field";

const ArgumentValueField: FC<{
  name: `arguments.${number}.value`;
  parameter: ParameterFragment;
}> = ({ name, parameter }) => {
  const { watch, register } = useFormContext();
  const label = `${parameter.name}:`;
  const field = register(name);

  switch (parameter.kind) {
    case ParameterKindEnum.Connection:
      return (
        <ConnectionSelectField
          required
          {...field}
          placeholder={!watch(name)}
          label={label}
        />
      );
    case ParameterKindEnum.Vault:
      return (
        <VaultSelectField
          required
          {...field}
          placeholder={!watch(name)}
          label={label}
        />
      );
    case ParameterKindEnum.Text:
      return <TextAreaField required {...field} label={label} />;
    case ParameterKindEnum.String:
      return <InputField required {...field} label={label} type="text" />;
    case ParameterKindEnum.Number:
      return <InputField required {...field} label={label} type="number" />;
    case ParameterKindEnum.Boolean:
      return <CheckboxField {...field} label={label} />;
  }
};

export const DashboardRunsForm: FC<{
  workflow: WorkflowFragment;
  loading?: boolean;
  save(_: RunInput): void;
}> = ({ loading, workflow, save }) => {
  const parameters = workflow.parameters;

  const form = useForm<RunInput>({
    defaultValues: {
      arguments: parameters.map((parameter) => ({ name: parameter.name })),
    },
  });

  const { fields } = useFieldArray({
    control: form.control,
    name: "arguments",
  });

  const onSubmit = async (input: RunInput) => {
    if (loading) return;
    save(input);
  };

  return (
    <Page>
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(onSubmit)}>
          {fields.map((field, index) => (
            <ArgumentValueField
              key={field.id}
              name={`arguments.${index}.value`}
              parameter={parameters[index]}
            />
          ))}

          <Button type="submit" loading={loading}>
            Run
          </Button>
        </Form>
      </FormProvider>
    </Page>
  );
};
