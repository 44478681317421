import { type FC } from "react";
import { useFormContext } from "react-hook-form";

import { useDashboardConnectionsFieldQuery } from "@app_schema";

import { BaseField } from "@styled/base_field";
import { LabelCheckbox } from "@styled/label_checkbox";
import { LabelCheckboxDescription } from "@styled/label_checkbox_description";
import { LabelCheckboxName } from "@styled/label_checkbox_name";

import { ConnectionTitle } from "./connection_title";

export const DashboardConnectionsField: FC<{
  name: string;
  label?: string;
}> = ({ name, label }) => {
  const { register } = useFormContext();
  const { data } = useDashboardConnectionsFieldQuery();
  const connections = data?.connections;

  if (!connections) return null;

  return (
    <BaseField name="connection_ids" label={label ?? "Connections:"}>
      {() => (
        <div className="flex flex-col gap-2">
          {connections.map((connection) => (
            <LabelCheckbox {...register(name)} value={connection.id}>
              <div>
                <LabelCheckboxName>
                  <ConnectionTitle connection={connection} />
                </LabelCheckboxName>
                <LabelCheckboxDescription>
                  UID: {connection.uid}
                </LabelCheckboxDescription>
              </div>
            </LabelCheckbox>
          ))}
        </div>
      )}
    </BaseField>
  );
};
