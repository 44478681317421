import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  type RunInput,
  useDashboardWorkflowsDetailsQuery,
  useWorkflowRunMutation,
} from "@app_schema";

import { DashboardRunsForm } from "./runs_form";

export const DashboardRunsNew: FC = () => {
  const navigate = useNavigate();
  const { workflowID } = useParams<{ workflowID: string }>();
  if (!workflowID) throw new Error("missing workflowID");

  const { data } = useDashboardWorkflowsDetailsQuery({
    variables: { workflowID },
  });

  const [execute, { loading }] = useWorkflowRunMutation();

  const workflow = data?.workflow;

  const save = async (input: RunInput) => {
    const response = await execute({ variables: { workflowID, input } });
    const run = response.data?.workflow.run.run;
    if (run) navigate(`/dashboard/runs/${run.id}`);
  };

  if (!workflow) return null;

  return (
    <DashboardRunsForm workflow={workflow} save={save} loading={loading} />
  );
};
