import { type FC } from "react";
import { Link } from "react-router-dom";

import { type Blog__Article } from "@app_schema";

export const BlogArticleTags: FC<{
  article: Pick<Blog__Article, "tags">;
}> = ({ article }) => (
  <div className="flex flex-wrap gap-2">
    {article.tags.map((tag) => (
      <Link
        className="font-medium text-indigo-600 hover:underline"
        to={`/blog/tagged/${tag}`}
      >
        #{tag}
      </Link>
    ))}
  </div>
);
