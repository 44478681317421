import { type LoaderFunctionArgs } from "react-router-dom";

import {
  BlogListDocument,
  type BlogListQuery,
  type BlogListQueryVariables,
} from "@app_schema";

import { client } from "@application/config/apollo";

export const BLOG_LIST_LOADER = async (props: LoaderFunctionArgs) => {
  const { data } = await client.query<BlogListQuery, BlogListQueryVariables>({
    query: BlogListDocument,
    variables: { tag: props.params.tag },
  });

  return data;
};
