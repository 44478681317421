import { type FC } from "react";
import { useNavigate } from "react-router";

import { faCirclePlay } from "@fortawesome/pro-duotone-svg-icons/faCirclePlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { type WorkflowFragment, useWorkflowRunMutation } from "@app_schema";

import { Button } from "@styled/button";

export const WorkflowsRunButton: FC<{
  workflow: WorkflowFragment;
}> = ({ workflow }) => {
  const navigate = useNavigate();
  const [execute, { loading }] = useWorkflowRunMutation({
    variables: { workflowID: workflow.id },
  });

  const onClick = async () => {
    if (workflow.parameters.length > 0)
      navigate(`/dashboard/workflows/${workflow.id}/runs/new`);
    else {
      const response = await execute();
      const run = response.data?.workflow.run.run;
      if (run) navigate(`/dashboard/runs/${run.id}`);
    }
  };

  return (
    <Button type="button" loading={loading} onClick={onClick}>
      <FontAwesomeIcon icon={faCirclePlay} /> Run
    </Button>
  );
};
