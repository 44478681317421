import { forwardRef } from "react";

import { BaseField, type BaseFieldProps } from "./base_field";
import { Select, SelectProps } from "./select";

export type SelectFieldProps = BaseFieldProps & SelectProps;

export const SelectField = forwardRef<HTMLSelectElement, SelectFieldProps>(
  ({ id, icon, label, errors, name, hint, tooltip, ...props }, ref) => (
    <BaseField
      id={id}
      name={name}
      hint={hint}
      tooltip={tooltip}
      label={label}
      icon={icon}
      errors={errors}
    >
      {({ state }) => (
        <Select id={id} name={name} state={state} ref={ref} {...props} />
      )}
    </BaseField>
  ),
);

SelectField.displayName = "SelectField";
